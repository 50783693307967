import Contrainer from "./Container";
import Job from "./Job";

const Experience = () => (
  <section>
    <Contrainer>
      <h2>Experience</h2>
      <Job
        acr="X"
        heading="Contract Frontend Developer"
        title="Xero"
        color="#fff"
        background="#00B1DB"
        date="May. 2021 - Nov 2021"
        Content={(
          <>
            <p>Specialising in React, TypeScript, Unit Testing, Storybook, Java CMS integration</p>
            <p>Xero is a New Zealand-based technology company, providing cloud-based accounting software for small and medium-sized businesses. They have very talented developers with rigorous standards. It was an amazing experience being part of the team and contributing to their website.</p>
          </>
        )}
      />
      <Job
        acr="F"
        heading="Contract Frontend Developer"
        title="Flick Electric | Z Electric"
        color="#000"
        background="#F7E002"
        date="Nov 2020 - Mar 2021"
        Content={(
          <>
            <p>Specialising in React, CSS frameworks, Gatsby, and Craft CMS.</p>
            <p>Flick Electric Co is an independent power company. I expanded their existing HTML & CSS frameworks and integrated it into a Gatsby content management system. I also did the same for Z Electric.</p>
          </>
        )}
      />
      <Job
        acr="DM"
        heading="Frontend Developer"
        title="Demodern - Creative Technologies"
        color="#01F7A3"
        background="#000"
        date="Mar 2019 - Apr 2020"
        Content={(
          <>
            <p>Specialising in React, React Native, TypeScript, Redux, and Spark AR - Augmented Reality</p>
            <p>
              Demodern is an award winning creative digital agency based in Cologne, Germany<br />
              I primarily worked on a mobile app called Snipes, which is a subsidiary of Nike, and one of Europe&lsquo;s largest and most hip shoe retailers. <br />
              I also created augmented reality games with Spark for Instagram.</p>
          </>
        )}
      />
      <Job
        acr="K"
        heading="Contract Frontend Developer"
        title="Konvoi is a creative agency for Lifestyle, Travel, Fashion, Design Value, Communication, Strategy"
        color="#fff"
        background="#000"
        date="Nov 2018 - Feb 2019"
        Content={(
          <>
            <p>Specialising in React, React Native, TypeScript, Redux.</p>
            <p>
              New to React and React Native, I helped the team familiarize themselves with the technology, and bring a meditation app to life.
            </p>
          </>
        )}
      />
      <Job
        acr="F"
        heading="Designer & Frontend Developer"
        title="Kreativgebiet | Fintory"
        color="#fff"
        background="#000"
        date="Jan 2016 - Nov 2018"
        Content={(
          <>
            <p>Specialising in UI/UX Design, React, React Native, TypeScript, Redux.</p>
            <p>Fintory is a design and development agency specialized in creating simple and performant user experiences. I actively collaborated with venture capitalists, designing and developing protype finance apps.</p>
          </>
        )}
      />
      <Job
        acr="Ap"
        heading="Frontend Developer"
        title="Appcom | Nanogiants"
        color="#10114A"
        background="#E40253"
        date="Feb 2016 - Feb 2017"
        Content={(
          <>
            <p>Specialising in HTML, CSS, React, Phonegap, and WordPress.</p>
            <p>I primarily created HTML, CSS, and WordPress websites. Having had little experience with advanced JavaScript frameworks. Appcom-Nanogaints was a great opportunity to get familiar with React and Phonegap.</p>
          </>
        )}
      />
      <Job
        acr="W"
        heading="Frontend Designer & Developer"
        title="Wunderknaben"
        color="#fff"
        background="#A9C40A"
        date="May. 2014 - Apr. 2016"
        Content={(
          <>
            <p>Specialising in HTML, CSS, jQuery and WordPress</p>
            <p>Wunderknaben is a social media communications&rsquo; company. I delivered a variety of projects ranging from Facebook games, campaign sites, and WordPress websites for some big name clients like Loreal.</p>
          </>
        )}
      />
      <Job
        acr="P+"
        heading="Frontend Developer"
        title="Practice Plus"
        color="#181818"
        background="#0281A9"
        date="Feb. 2011 - Mar. 2013"
        Content={(
          <>
            <p>Specialising in HTML, CSS, jQuery and Adobe Business Catalyst</p>
            <p>PracticePlus is a digital marketing agency for accountants. This was my first web development job and a great place to hone frontend development skills. Having delivered a high volume of affordable websites at a rapid pace.</p>
          </>
        )}
      />
    </Contrainer>
  </section>
);

export default Experience;