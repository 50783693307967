import Experience from './Experience';
import Footer from './Footer';
import Hero from './Hero';
import Intro from './Intro';
import Contact from './Contact';

function App() {
  return (
    <>
      <Hero />
      <Intro />
      <Footer />
      <div className='pagebreak' />
      <div className='pagestart' />
      <Experience />
      <Contact />
    </>
  );
}

export default App;
