import './Hero.css';
import PrintIcon from './PrintIcon';

const Hero = () => (
  <>
    <section className='hero'>
      <h1>Malte S. Boeing</h1>
      <div className='malte-photo'>
        <img alt="Malte Boeing" src="./malte.jpg" />
        <h3>UI Developer</h3>
      </div>
    </section>
    <PrintIcon />
  </>
)

export default Hero;