import './Job.css';

const Circle = ({ initial, color, background, }) => (
  <div>
    <div className='job-circle' style={{ backgroundColor: background, color }}>
      {initial}
    </div>
  </div>
)

const Job = ({ acr, heading, title, color, background, date, Content }) => (
  <article className="job-container">
    <Circle initial={acr} color={color} background={background} />
    <div>
      <h3 className="job-heading">{heading}</h3>
      <h4 className="job-title">{title}</h4>
      <p className="job-date">{date}</p>
      <div className="job-text">
        {Content}
      </div>
      
    </div>
  </article>
);

export default Job;