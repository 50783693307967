import Contrainer from './Container';
import { ArcElement } from 'chart.js'
import { Doughnut, Bar } from 'react-chartjs-2';
import './Intro.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const color1 = "#E158FF";
const color2 = "#6E21EC";
const color3 = "#58AFFF";

const dataAnatomy = {
  labels: [
    'Frontend',
    'Creative',
    'Backend'
  ],
  datasets: [{
    data: [50, 30, 20],
    backgroundColor: [color1, color2, color3],
    hoverOffset: 4,
  }]
};

const options = {
  indexAxis: "y",
  maintainAspectRatio: false,
};

const labels = [ 'CSS', 'React', 'TypeScript', 'Prismic', 'GraphQL', 'Node Apollo', "MongoDB"];
const data = {
  labels,
  datasets: [
    {
      label: 'Fullstack JS',
      data: [90, 85, 75, 60, 60, 50, 40],
      backgroundColor: [color1, color2, color3, color1, color2, color3, color1],
    },
  ],
};

const Intro = () => (
  <section className='intro-section'>
    <Contrainer>
      <div className='intro-text'>
        <p>A digital creative with a strong eye for composition, typography and colours. Primarily focusing on Frontend Develoment, I don&lsquo;t shy away from design work or server side development. What ever it takes to bring beautiful websites and apps to life.</p>
      </div>
    </Contrainer>
    <div className='intro-outer'>
      <Contrainer className="intro-container">
        <div className='intro-col'>
          <h2>Anatomy</h2>
          <Doughnut data={dataAnatomy} />
        </div>
        <div className='intro-col intro-tools' style={{ height: 325 }}>
          <h2>Tools</h2>
          <Bar options={options} data={data} />
        </div>
      </Contrainer>
    </div>
  </section>
);

export default Intro;