import Contrainer from "./Container";
import './Footer.css';

const Footer = () => (
  <div className="printfooter">
    <Contrainer className="printfooter-container">
      <div>
        <h3>www.malte.nz</h3>
      </div>
      <div>
        <h3>hello@malte.nz</h3>
      </div>
    </Contrainer>
  </div>
)

export default Footer;