import React from "react";
import './PrintIcon.css';

function Icon() {

  const print = () => {
    window.print();
  }

  return (
    <button onClick={print} className="printicon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" fillRule="nonzero">
          <path d="M48 10h-8V0H8v10H0v26h8v12h19.038c5.894 0 12.058-7.154 12.868-12H48V10zM12 4h24v6H12V4zm17.382 33.296S32.32 44 25.382 44H12V28h24v5.296c0 7.188-6.618 4-6.618 4zM43 16a1 1 0 110-2 1 1 0 010 2zM32 34H16v-2h16v2zm-6 2H16v2h10v-2z"></path>
        </g>
      </g>
    </svg>
    </button>
  );
}

export default Icon;
