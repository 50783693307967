import Contrainer from "./Container";
import './Contact.css';

const Contact = () => (
  <div className="contact">
    <Contrainer className="contact-container">
      <div>
        <h3><a href="mailto:hello@malte.nz">hello@malte.nz</a></h3>
      </div>
    </Contrainer>
  </div>
)

export default Contact;